.logout {
   
        color: #fff;
        font-weight: bold;
        font-size: 20px;
        width: 190px;
        height: 56px;	
        background-color: #FD3737;
        border-radius: 50px;
        border: none;
        font-family: 'Proxima Nova Lt', sans-serif;
        }








