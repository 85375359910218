.container {
    width: 90%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}

.headerTop {

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0 20px 0;
    border-bottom: 1px solid #2c2c2c;
}

.logoBlock {
    display: flex;
}

.menuTop {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
}

.logo {
    width: 225px;
    height: 50px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    background-image: url("../../../image/logo.png");
    background-size: cover;
}


.menuBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 30px;
}

.menuBlockItem {
    font-weight: bold;
    font-size: 14px;
    color: #2C2C2C;
    text-decoration: none;
    font-family: 'Proxima Nova Lt', sans-serif;
    cursor: pointer;
}

.menuBlockItem:hover {
    color: #2e5775;

}

.menuPhone {
    font-size: 20px;
    font-weight: bold;
    margin-top: 15px;
    font-family: 'Proxima Nova Lt', sans-serif;
}

.menuEnter {
    display: flex;
    column-gap: 50px;
    align-items: flex-end;

}


.blockCard {
    display: flex;
    align-items: flex-start;
}

.profile {
    width: 20px;
    height: 22px;
    margin: 0;
}

.cabinet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;
}

h5 {
    font-size: 16px;
}

.card {
    width: 24px;
    height: 22px;
    margin: 0;
}

.cardCircle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #FD3737;
    font-size: 12px;
    font-weight: bold;
    color: #2e5775;
    text-align: center;
    margin-left: -2px;
}

.enterenter {
    color: #2C2C2C;
}

.regLog {
    display: flex;
}

.enter {
    display: flex;
    cursor: pointer;
    font-size: 14px;
}

.close {
    width: 12px;
    height: 12px;
}
.burgerLine {
    width: 30px;
    height: 30px;
 }

@media (max-width: 768px) {
    .menuEnter {
        column-gap: 30px;
    }
    .logo {
        width: 50px;
        height: 50px;
        background-image: url("../../../image/Logo_mini.png");
       }
       .headerTop {
        padding: 20px 0 20px 0;

       }

}

@media (max-width: 525px) {
   


.logo {
    width: 40px;
    height: 40px;
 }

       .menuEnter {
        column-gap: 30px;
    }

    .menuPhone {
        display: none;
    }

    .profile {
        width: 18px;
       height: 20px;
    }

    .card {
        width: 20px;
        height: 18px;
    }
    
}

@media (max-width: 475px) {

    .logo {
        width: 30px;
        height: 30px;
     }

   
    }    