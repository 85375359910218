.popBasket {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
	position: absolute;
	top: 15%;
	right: 5%;
	align-content: flex-start;
	align-items: flex-start;
	z-index: 100;
}

.basket {
	position: absolute;
	width: 450px;
	max-width: 100%;
	height: auto;
	right: 0;
	transition: right 1s;
	background-color: #fff;
	z-index: 120;
	font-family: 'Original Garamond';
	border: 1px solid #2c2c2c;
}

.closeBas {
	cursor: pointer;
	font-size: 32px;
	position: absolute;
	right: -35px;
	color: #000;
}

.basketHid {
	display: none;
}

.blockTop {
	background: #2c2c2c;
	height: 60px;
	display: flex;
	align-items: center;

}


.basketTitle {
	font-size: 20px;
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	margin-left: 36%;
}

.blockProduct {
	display: flex;
	flex-direction: column;
	column-gap: 30px;
	align-items: center;

}

.descript {
	width: 80%;
	display: flex;
	flex-direction: column;
	margin-top: 30px;

}

.produckBasket {
	display: flex;
	justify-content: space-around;
	column-gap: 40px;
}

.blockTextBasket {
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid #2c2c2c;
	width: 90%;
}

.name {
	font-size: 16px;
	font-weight: bold;
}

.blockTotal {
	display: flex;
	align-items: flex-end;
	padding-top: 20px;
}


.card {
	padding: 10px 0;
	text-align: end;
	color: #2e5775;
	font-size: 12px;

}

.wrapperAdd {

	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.add {
	display: flex;
	width: 75px;
	margin-left: 10px;
}

.minus, .amount, .plus {

	display: flex;
	justify-content: center;
	align-items: center;
	width: 25px;
	height: 25px;
}


.minus, .plus, .del {
	cursor: pointer;
}

.amount {
	font-weight: bold;
}


.basketBtn {
	font-weight: bold;
	font-size: 18px;
	width: 36%;
	height: 38px;
	background-color: #FD3737;
	color: #fff;
	border-radius: 50px;
	border: none;
	margin: 30px 0;
	font-family: 'Proxima Nova Lt', sans-serif;
}

@media (max-width: 1220px) {
	.basket {
		width: 40%;
	}

}

@media (max-width: 768px) {
	.basket {
		width: 55%;
	}

	.produckBasket {
		column-gap: 30px;
	}
}

@media (max-width: 425px) {
	.basket {
		width: 100%;
	}

	.popBasket {
		width: 90%;
		position: absolute;
		top: 10%;
		right: 27px;
	}

}