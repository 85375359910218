.preloder {
    height: 100%;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: absolute;
    opacity: 0.9;
    background-color: #fff;
}

  .preloder p  {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 60px;
    font-family: 'Original Garamond', serif;
    margin-right: 3px;
}

.b8, .e9, .l10 { 
    text-transform: uppercase;
	color: #2e5775;
}

@keyframes wave {
	from {
	  transform: translateY(200%);
	}
	to {
	  transform: translateY(0);
	} 
  }

.p1 {
    animation: wave 1s linear  1.0s 1 backwards;
}
.r2 {
    animation: wave 1s linear  1.2s 1 backwards;
}
.e3 {
    animation: wave 1s linear  1.4s 1 backwards;
}
.m4 {
    animation: wave 1s linear  1.6s 1 backwards;
}
.e5 {
    animation: wave 1s linear  1.8s 1 backwards;
}
.k6 {
    animation: wave 1s linear  2s 1 backwards;
}
.s7 {
    animation: wave 1s linear  2.2s 1 backwards;
}
.b8 {
    animation: wave 1s linear  2.4s 1 backwards;
}
.e9 {
    animation: wave 1s linear  2.6s 1 backwards;
}
.l10 {
    animation: wave 1s linear  2.8s 1 backwards;
}

@media (max-width: 1024px) {
    .preloder p  {
        font-size: 40px;
        }
	
}


@media (max-width: 475px) {
    .preloder p  {
        font-size: 30px;
        }
	
}