@font-face {
  font-family: 'Original Garamond';
  src: local('fonts'),
    url('./fonts/OriginalGaramondCBT.ttf') format('truetype');
  
}


@font-face {
  font-family: 'Original Garamond';
  src: local('fonts'),
    url('./fonts/OriginalGaramondSCCBT.ttf') format('truetype');
  
}
  
@font-face {
  font-family: 'Original Garamond';
  src: local('fonts'),
    url('./fonts/OriginalGaramondCBT-Bold.ttf') format('truetype');
  
}
@font-face {
  font-family: 'Original Garamond';
  src: local('fonts'),
    url('./fonts/OriginalGaramondCBT-BoldItalic.ttf') format('truetype');
  
}

@font-face {
  font-family: 'Original Garamond';
  src: local('fonts'),
    url('./fonts/OriginalGaramondCBT-Italic.ttf') format('truetype');
  
}

@font-face {
  font-family: 'Proxima Nova Lt';
  src: local('fonts'),
    url('./fonts/proximanova_black.ttf') format('truetype');
  
}
@font-face {
  font-family: 'Proxima Nova Lt';
  src: local('fonts'),
    url('./fonts/proximanova_boldit.otf') format('truetype');
  
}

@font-face {
  font-family: 'Proxima Nova Lt';
  src: local('fonts'),
    url('./fonts/proximanova_light.otf') format('truetype');
  
}
@font-face {
  font-family: 'Proxima Nova Lt';
  src: local('fonts'),
    url('./fonts/proximanova_regular.ttf') format('truetype');
  
}

.font-dancing-script {
  font-family: 'Proxima Nova Lt', sans-serif;

}

.font-dancing-script {
  font-family: 'Original Garamond', serif;

}



* {
	padding: 0px;
	margin: 0px;
	border: none;
  }

*,
*::before,
*::after {
	box-sizing: border-box;
}



/* Links */



a:hover  {
    /* color: inherit; */
    text-decoration: none;
}

/* Common */

aside, nav, footer, header, section, main {
	display: block;
}

/*h1, h2, h3, h4, h5, h6, p {
    font-size: inherit;
	font-weight: inherit;
}*/

ul, ul li {
	list-style: none;
}


img, svg {

	height: auto;
}

address {
  font-style: normal;
}

/* Form */

input, textarea, button, select {
	font-family: inherit;
    font-size: inherit;
    color: inherit;
   
}

input::-ms-clear {
	display: none;
}

button, input[type="submit"] {
    display: inline-block;
    box-shadow: none;
  
    background: none;
    cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
    outline: none;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

label {
	cursor: pointer;
}

legend {
	display: block;
}
