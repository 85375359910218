.picWrapper {
    width: 1200px;
    height: 600px;
    overflow: hidden;
    margin: 0 auto;

}

.picStartLora {
    width: 100%;
    height: 100%;
    background-image: url(../../image/kadr_1.jpg),
        url(../../image/kadr_3.jpg),
        url(../../image/kadr_4_LoRa.jpg);

    animation: picLora 6s 1 forwards;
    background-repeat: no-repeat;
background-size: 100% 100%;

}

.picLora {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    background-image: url(../../image/kadr_1.jpg),
        url(../../image/kadr_3.jpg),
        url(../../image/kadr_4_LoRa.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.picWrapper:hover .picLora {
    width: 100%;
    height: 100%;
    background-image: url(../../image/kadr_1.jpg),
        url(../../image/kadr_3.jpg),
        url(../../image/kadr_4_LoRa.jpg);
    animation: picLora 6s 1 forwards;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}



@keyframes picLora {
    0% {
        background-image: url(../../image/kadr_1.jpg);
        transform: scale(1);
        margin-top: 0%;
        margin-left: 0%;
        opacity: 1;
    }



    20% {
        background-image: url(../../image/kadr_3.jpg);
        transform: scale(1);
        margin-left: 0%;
        margin-top: 0%;
        opacity: 1;
    }

    35% {
        background-image: url(../../image/kadr_3.jpg);
        transform: scale(3);
        margin-top: 20%;
        margin-left: 130%;
        opacity: 1;
    }

    40% {
        background-image: url(../../image/kadr_3.jpg);
        transform: scale(3);
        margin-top: 20%;
        margin-left: 130%;
        opacity: 0;

    }

    41% {
        background-image: url(../../image/kadr_4_LoRa.jpg);
        transform: scale(1.3);
        margin-top: -10%;
        margin-left: 40%;
        opacity: 0;

    }

    50% {
        background-image: url(../../image/kadr_4_LoRa.jpg);
        transform: scale(1.3);
        margin-top: -10%;
        margin-left: 40%;
        opacity: 1;
    }

    70% {
        background-image: url(../../image/kadr_4_LoRa.jpg);
        transform: scale(1.3);
        margin-top: -10%;
        margin-left: 40%;
    }


    100% {
        background-image: url(../../image/kadr_4_LoRa.jpg);
        transform: scale(1);
        margin-left: 0%;
        margin-top: 0%;
    }
}

.picStartIot {
    height: 100%;
    background-image: url(../../image/kadr_1.jpg),
        url(../../image/kadr_3.jpg),
        url(../../image/kadr_4_iot.jpg);

    animation: picIot 6s 1 forwards;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.picIot {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    background-image: url(../../image/kadr_1.jpg),
        url(../../image/kadr_3.jpg),
        url(../../image/kadr_4_iot.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.picWrapper:hover .picIot {
    width: 100%;
    height: 100%;
    background-image: url(../../image/kadr_1.jpg),
        url(../../image/kadr_3.jpg),
        url(../../image/kadr_4_iot.jpg);
    animation: picIot 6s 1 forwards;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}



@keyframes picIot {
    0% {
        background-image: url(../../image/kadr_1.jpg);
        transform: scale(1);
        margin-top: 0%;
        margin-left: 0%;
        opacity: 1;
    }



    20% {
        background-image: url(../../image/kadr_3.jpg);
        transform: scale(1);
        margin-left: 0%;
        margin-top: 0%;
        opacity: 1;
    }

    35% {
        background-image: url(../../image/kadr_3.jpg);
        transform: scale(3);
        margin-top: 20%;
        margin-left: 130%;
        opacity: 1;
    }

    40% {
        background-image: url(../../image/kadr_3.jpg);
        transform: scale(3);
        margin-top: 20%;
        margin-left: 130%;
        opacity: 0;

    }

    41% {
        background-image: url(../../image/kadr_4_iot.jpg);
        transform: scale(1.3);
        margin-top: -10%;
        margin-left: 40%;
        opacity: 0;

    }

    50% {
        background-image: url(../../image/kadr_4_iot.jpg);
        transform: scale(1.3);
        margin-top: -10%;
        margin-left: 40%;
        opacity: 1;
    }

    70% {
        background-image: url(../../image/kadr_4_iot.jpg);
        transform: scale(1.3);
        margin-top: -10%;
        margin-left: 40%;
    }


    100% {
        background-image: url(../../image/kadr_4_iot.jpg);
        transform: scale(1);
        margin-left: 0%;
        margin-top: 0%;
    }
}

.picStartMBus {
    height: 100%;
    background-image: url(../../image/kadr_1.jpg),
        url(../../image/kadr_3.jpg),
        url(../../image/kadr_4_MBus.jpg);

    animation: picMBus 6s 1 forwards;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.picMBus {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    background-image: url(../../image/kadr_1.jpg),
        url(../../image/kadr_3.jpg),
        url(../../image/kadr_4_MBus.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.picWrapper:hover .picMBus {
    width: 100%;
    height: 100%;
    background-image: url(../../image/kadr_1.jpg),
        url(../../image/kadr_3.jpg),
        url(../../image/kadr_4_MBus.jpg);
    animation: picMBus 6s 1 forwards;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}



@keyframes picMBus {
    0% {
        background-image: url(../../image/kadr_1.jpg);
        transform: scale(1);
        margin-top: 0%;
        margin-left: 0%;
        opacity: 1;
    }



    20% {
        background-image: url(../../image/kadr_3.jpg);
        transform: scale(1);
        margin-left: 0%;
        margin-top: 0%;
        opacity: 1;
    }

    35% {
        background-image: url(../../image/kadr_3.jpg);
        transform: scale(3);
        margin-top: 20%;
        margin-left: 130%;
        opacity: 1;
    }

    40% {
        background-image: url(../../image/kadr_3.jpg);
        transform: scale(3);
        margin-top: 20%;
        margin-left: 130%;
        opacity: 0;

    }

    41% {
        background-image: url(../../image/kadr_4_MBus.jpg);
        transform: scale(1.3);
        margin-top: -10%;
        margin-left: 40%;
        opacity: 0;

    }

    50% {
        background-image: url(../../image/kadr_4_MBus.jpg);
        transform: scale(1.3);
        margin-top: -10%;
        margin-left: 40%;
        opacity: 1;
    }

    70% {
        background-image: url(../../image/kadr_4_MBus.jpg);
        transform: scale(1.3);
        margin-top: -10%;
        margin-left: 40%;
    }


    100% {
        background-image: url(../../image/kadr_4_MBus.jpg);
        transform: scale(1);
        margin-left: 0%;
        margin-top: 0%;
    }
}
.picStartNBiot {
    height: 100%;
    background-image: url(../../image/kadr_1.jpg),
        url(../../image/kadr_3.jpg),
        url(../../image/kadr_4_NBiot.jpg);

    animation: picNBiot 6s 1 forwards;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.picNBiot {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    background-image: url(../../image/kadr_1.jpg),
        url(../../image/kadr_3.jpg),
        url(../../image/kadr_4_NBiot.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.picWrapper:hover .picNBiot {
    width: 100%;
    height: 100%;
    background-image: url(../../image/kadr_1.jpg),
        url(../../image/kadr_3.jpg),
        url(../../image/kadr_4_NBiot.jpg);
    animation: picNBiot 6s 1 forwards;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}



@keyframes picNBiot {
    0% {
        background-image: url(../../image/kadr_1.jpg);
        transform: scale(1);
        margin-top: 0%;
        margin-left: 0%;
        opacity: 1;
    }



    20% {
        background-image: url(../../image/kadr_3.jpg);
        transform: scale(1);
        margin-left: 0%;
        margin-top: 0%;
        opacity: 1;
    }

    35% {
        background-image: url(../../image/kadr_3.jpg);
        transform: scale(3);
        margin-top: 20%;
        margin-left: 130%;
        opacity: 1;
    }

    40% {
        background-image: url(../../image/kadr_3.jpg);
        transform: scale(3);
        margin-top: 20%;
        margin-left: 130%;
        opacity: 0;

    }

    41% {
        background-image: url(../../image/kadr_4_NBiot.jpg);
        transform: scale(1.3);
        margin-top: -10%;
        margin-left: 40%;
        opacity: 0;

    }

    50% {
        background-image: url(../../image/kadr_4_NBiot.jpg);
        transform: scale(1.3);
        margin-top: -10%;
        margin-left: 40%;
        opacity: 1;
    }

    70% {
        background-image: url(../../image/kadr_4_NBiot.jpg);
        transform: scale(1.3);
        margin-top: -10%;
        margin-left: 40%;
    }


    100% {
        background-image: url(../../image/kadr_4_NBiot.jpg);
        transform: scale(1);
        margin-left: 0%;
        margin-top: 0%;
    }
}
.picStartRS {
    height: 100%;
    background-image: url(../../image/kadr_1.jpg),
        url(../../image/kadr_3.jpg),
        url(../../image/kadr_4_NBiot.jpg),
        url(../../image/kadr_4_RS.jpg);

    animation: picRS 6s 1 forwards;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.picRS {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    background-image: url(../../image/kadr_1.jpg),
        url(../../image/kadr_3.jpg),
        url(../../image/kadr_4_RS.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.picWrapper:hover .picRS {
    width: 100%;
    height: 100%;
    background-image: url(../../image/kadr_1.jpg),
        url(../../image/kadr_3.jpg),
        url(../../image/kadr_4_RS.jpg);
    animation: picRS 6s 1 forwards;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}



@keyframes picRS {
    0% {
        background-image: url(../../image/kadr_1.jpg);
        transform: scale(1);
        margin-top: 0%;
        margin-left: 0%;
        opacity: 1;
    }



    20% {
        background-image: url(../../image/kadr_3.jpg);
        transform: scale(1);
        margin-left: 0%;
        margin-top: 0%;
        opacity: 1;
    }

    35% {
        background-image: url(../../image/kadr_3.jpg);
        transform: scale(3);
        margin-top: 20%;
        margin-left: 130%;
        opacity: 1;
    }

    40% {
        background-image: url(../../image/kadr_3.jpg);
        transform: scale(3);
        margin-top: 20%;
        margin-left: 130%;
        opacity: 0;

    }

    41% {
        background-image: url(../../image/kadr_4_RS.jpg);
        transform: scale(1.3);
        margin-top: -10%;
        margin-left: 40%;
        opacity: 0;

    }

    50% {
        background-image: url(../../image/kadr_4_RS.jpg);
        transform: scale(1.3);
        margin-top: -10%;
        margin-left: 40%;
        opacity: 1;
    }

    70% {
        background-image: url(../../image/kadr_4_RS.jpg);
        transform: scale(1.3);
        margin-top: -10%;
        margin-left: 40%;
    }


    100% {
        background-image: url(../../image/kadr_4_RS.jpg);
        transform: scale(1);
        margin-left: 0%;
        margin-top: 0%;
    }
}
