.futter {
	position: relative;
	background-color: #2c2c2c;
	color: #fff;
	z-index: 100;
}

.container {
	width: 90%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;


}

.wrapperFutter {
	display: flex;
	flex-direction: column;
	padding-top: 50px;

}

.futterTop {
	display: flex;
	justify-content: space-between;
	margin-bottom: 50px;
	flex-wrap: wrap;

}

.futterTextTop {
	font-size: 40px;
	font-weight: lighter;
	font-family: 'Proxima Nova Lt', sans-serif;
}

.futterTextBottom {
	font-size: 50px;
	font-weight: bold;
	text-transform: uppercase;
	padding-top: 10px;
	font-family: 'Proxima Nova Lt', sans-serif;
}


.futterCenter, .futterCenterMenu {
	display: flex;
	flex-direction: column;
}

.futterTextList {
	font-size: 16px;
	padding-bottom: 10px;
}

.futterTextListNav {
	font-size: 16px;
	padding-bottom: 10px;
	cursor: pointer;
}

.futterTextListNav:hover {
	color:#2e5775;
}

.futterTextListNav {
	color: #e7eaef;
}

.futterWrapper {

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.futterRight {
	width: 100%;
	background-color: #2c2c2c;
	display: flex;
	flex-direction: column;
	margin-top: 13px;
	color: #2c2c2c;
}


.futterQvest {
	font-size: 16px;
	padding-top: 50px;
}

.futterMail,
.futterPhone {
	margin-top: 30px;
	height: 50px;

}

.futterMail {
	border-bottom: 1px solid #fff;
}

.futterBottom {
	display: flex;
	justify-content: space-between;
	border-top: 1px solid #fff;
	padding: 30px 0 30px 0;


}

input {
	width: 100%;
	height: 30px;
	margin-top: 20px;
	background-color: #fff;
}

textarea {
	width: 100%;
	height: 80px;
	background-color: #fff;
	margin-top: 20px;
}

input::placeholder,
textarea::placeholder {
	font-size: 16px;
	color: #ccc;
	padding: 5px 0 0 5px;
	vertical-align: top;
}

.futterBtn {

	font-size: 16px;
	width: 130px;
	height: 40px;
	font-weight: bold;
	color: #fff;
	background-color: #FD3737;
	border-radius: 50px;
	border: none;
	font-family: 'Proxima Nova Lt', sans-serif;
	margin: 20px 0 0 45%;

}

.futterLogo {
	font-size: 24px;
	text-transform: uppercase;
	font-weight: bold;
}

.futterCopi {
	font-size: 12px;
}

@media (max-width: 1366px) {
	.partners {
		margin: 100px 0;
	}

}

@media (max-width: 1024px) {
	.futterTextTop {
		font-size: 30px;
	}

	.futterTextBottom {
		font-size: 40px;

	}
	
}

@media (max-width: 768px) {
	.futterRight {
		margin-top: 30px;
	}


	
.futterTop {
	column-gap: 50px;
}


.futterCenterMenu {
display: none;

}

.futterCenter {
	position: absolute;
	margin-top: 17%;
	
	}
}

@media (max-width: 611px) {
	
.futterCenter {
	margin-left: 65%;
	margin-top: 0;
	}
}

@media (max-width: 425px) {
	
	.futterCenter {
		position: relative;
		margin-left: 0;
		margin-top: 30px;
		}
.futterRight {
	margin-top: 10px;
}
		.futterWrapper {
			width: 80%;
			margin-top: 30px;
			align-items: flex-start;
		}
		.futterBtn {
			margin: 20px 0 0 58%;
		}
	}
	