@keyframes slide {
	from {
	  transform: translateX(-100%);
	}
	to {
	  transform: translateX(0%);
	}
  }
  
  .logos {
	overflow: hidden;
	padding: 60px 0;
	background: white;
	white-space: nowrap;
	position: relative;
  }
  
  .logos:before,
  .logos:after {
	position: absolute;
	top: 0;
	width: 250px;
	height: 100%;
	content: "";
	z-index: 5;
  }
  
  .logos:before {
	left: 0;
	background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
  }
  
  .logos:after {
	right: 0;
	background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  }
  
  .logos:hover .slide {
	animation-play-state: paused;
  }
  
  .slide {
	display: inline-block;
	animation: slide 35s infinite linear;
  }
  
  .slide img {
	margin: 0 40px;
  }