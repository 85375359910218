.orderForm {
    transition: right 1s;
    width: 30%;
    z-index: 30;
    position: absolute;
    display: none;
    right: 0;
    z-index: 110;

}

.close {
 
    width: 20px;
    height: 20px;
    position: absolute;
   right: 0;
   margin-top: 10px;
   margin-right: 10px;
    cursor: pointer;
    z-index: 40;
}
.blockTop {
    background: #fff;
	height: 70px;
    border: 1px solid #2c2c2c;
    display: flex;
    align-items: center;
 
} 
.form {
    
    width: 100%;
    height: auto;
    background-color: #2c2c2c;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    padding: 0 50px 50px 50px;

}

.tytleOrder {
    font-size: 20px;
    color: #2c2c2c;
text-transform: uppercase;
font-weight: bold;
font-family: 'Proxima Nova Lt', sans-serif;
margin-left: 30%;
}
.closeOrder {
    cursor:pointer;
     width: 26px;
     height: 26px;
     margin-left: 23%;
}

.name, .email, .phone,
.company, .unp,
.comment {
    font-size: 14px;
    padding-bottom: 10px;
} 

.nameForm, .mailForm, .phoneForm,
.companyForm, .unpForm, .commentForm  {
    font-family: 'Proxima Nova Lt', sans-serif;
    font-size: 16px;
    margin-top: 20px; 
    display: flex;
    flex-direction: column;
    width: 100%;

}

.inputName, .inputEmail, .inputPhone,
.inputCompany, .inputUnp {

     background-color: #fff;
     margin-top: 0;

}

.inputName::placeholder, .inputEmail::placeholder, .inputPhone::placeholder,
.inputCompany::placeholder, .inputUnp::placeholder, 
.inputComment::placeholder {
font-size: 16px;

}

.inputComment {
    margin-top: 0;
    width: 100%;
}


.formLogBtn {
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    width: 150px;
    height: 40px;
    background-color: #FD3737;
    border-radius: 50px;
    border: none;
    margin-top: 40px;
    font-family: 'Proxima Nova Lt', sans-serif;
    margin-left: 69%;

}
.form input  {
    font-size: 16px;
    color: #000;
}

.form textarea  {
    font-size: 16px;
    color: #000;
}

@media (max-width: 1220px) {
	.orderForm {
          width: 40%;
 }
	 
} 

@media (max-width: 768px) {
    .orderForm {
        width: 55%;   
     }
}

@media (max-width: 425px) {
    .orderForm {
        width: 100%;   
     }
}