.container {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    color: #2c2c2c;
    font-family: 'Proxima Nova Lt', sans-serif;
}

.blockTree {
    display: flex;
    margin: 50px 0;
    align-items: center;
}


.item {
    cursor: pointer;
    font-size: 16px;
    
}

.item:first-child {
    margin-right: 5px;
}
.item:last-child {
    cursor: none;
    color: #2e5775;
    font-style: italic;
margin-left: 5px;
}

h2 {
    font-size: 50px;
}

.text11 {
    color: #2e5775;
    font-style: italic;
    font-size: 50px;
    font-weight: bold;
}

.blockTop {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.picTeh {
    width: 1440px;
}


.blockContentLora {
    font-size: 22px;
    width: 30%;
    font-family: 'Original Garamond', serif;
}

.arhitect {
    display: flex;
    flex-direction: column;
}

.hArhitect,
.hTehnology {
    font-size: 30px;
    text-transform: uppercase;
    margin: 50px 0 10px 0;
}

.hTehnology {
    margin: 100px 0 10px 0;
}
.textArhitect {
    font-size: 20px;

}

.blockArhitect {
    display: flex;
    flex-direction: column;
    margin-top: 50px;

}

.listArhitect:hover {
    background-color: #2c2c2c;
    color: white;
    animation: doc 1s linear 1 backwards;
}

.listArhitect {
    display: flex;
    padding: 30px 0 0 0;
    font-size: 20px;
    animation: doc2 0.5s linear 1 backwards;
}

.textLeft {
    width: 30%;
    font-weight: bold;
}

.textRight {
    width: 70%;
    padding-bottom: 30px;
    border-bottom: 1px solid #2c2c2c;
}

.listCatalog {
    display: flex;
    flex-direction: column;
    margin: 50px 0 0 0;
    font-size: 20px;
    /*width: 65%;*/
}

.blockCatalog {
    position: relative;
    display: flex;
    padding: 30px 0;
    border-bottom: 1px solid #2c2c2c;
}

.blockCatalog img {

    opacity: 0;
    transition: all 1s;
}

.blockCatalog:hover img {
    opacity: 1;
}

.svkh {
    position: absolute;
    top: -38px;
    right: 20%;
}

.blockCatalog:first-child {
    /*border-top: 1px solid #2c2c2c;*/
    display: flex;
    justify-content: flex-start;
}

.numberPoductCatalog {
    margin-right: 20px;
}

.poductCatalog {
    width: 60%;
    cursor: pointer;
}

.textTarif {
    font-size: 16px;
    padding-top: 20px;
    width: 45%;
}

.textSlogan {
    padding: 50px 0;
    text-align: center;
    color: #FD3737;
    font-size: 22px;
    font-family: 'Original Garamond', serif;
}

@keyframes doc {
    from {
        background-color: white;
        color: #2c2c2c;

    }

    to {
        background-color: #2c2c2c;
        color: white;
    }

}

@keyframes doc2 {
    from {
        background-color: #2c2c2c;
        color: white;
    }

    to {
        background-color: white;
        color: #2c2c2c;

    }

}