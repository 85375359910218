.menuBurger {
    background-color: #2C2C2C;
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 50;
}

.container {
	width: 80%;
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
}

.wrapper {
    display: flex;
}

.burger {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.close {
    width: 20px;
    height: 20px;
    margin-top: 50px;
    position: absolute;
    right: 10%;
} 

.menuBurgerBlock {
    display: flex;
    flex-direction: column;
    padding: 100px 0;
 
}

.menuBurgerBlockItem {
    font-weight: bold;
    font-size: 26px;
    color: #fff;
    text-decoration: none;
    padding-bottom: 30px;
    font-family: 'Proxima Nova Lt', sans-serif;
}

.menuLine {
    border-top: 1px solid #fff;
}


.menuContact {
    color: #fff;
    font-weight: bold;
    font-size: 26px;
    margin-top: 40%;
}

.menuMail {
    margin-top: 30px;
}

@media (max-width: 1024px) {

    .menuBurgerBlockItem, .menuMail {
        font-size: 20px;
    }
    .menuContact {
        margin-top: 0;
    }
    .menuBurgerBlock {
        padding: 100px 0 0 0;
    }
    

}

@media (max-width: 768px) {

    .menuBurgerBlockItem, .menuMail {
        font-size: 20px;
    }

}

@media (max-width: 650px) {
    .menuBurger {
        width: 100%; 
    }
    
}