.partners {
position: relative;
padding: 150px 0;
z-index: 100;
background-color: #fff;
}

.container { 
	width: 90%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;

}

.partnersOur {
	font-size: 50px;
	text-transform: uppercase;
	font-family: 'Proxima Nova Lt', sans-serif;
}

.partnerLogoRight, .partnerLogoLeft {
	display: flex;
	column-gap: 10%;
	row-gap: 100px;
	margin-top: 100px;
}

.logoPic {
	width: 250px;
	height: 40px;
    margin-left: 0px;
	transition: 1s all;
	
}

@media (max-width: 1366px) {
    .partners {
		margin-top: 100px;
    }

}
