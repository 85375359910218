* {
	margin: 0;
	padding: 0;
}

body {
	color: #2C2C2C;

}


.container {
	width: 90%;
	max-width: 1100px;
	margin-left: auto;
	margin-right: auto;

}

.wisecityPic {
	position: absolute;
	right: 35px;
	top: 14vh;
	width: 200px;
}

.headerTop {
	display: flex;
	flex-direction: column;
	margin-top: 50px;
	row-gap: 30px;
	overflow: hidden;

}

.headerContent {
	display: flex;
	flex-direction: column;
	line-height: 1;
	width: 100%;
}

.headerTextLeft {
	text-align: left;
	font-size: 60px;
	font-family: 'Proxima Nova Lt', sans-serif;
}


.headerText {
	line-height: 1;
}

.headerTextBlock {
	display: flex;
	line-height: 0.8;
	font-size: 100px;
	font-weight: bold;
	padding-top: 30px;
	font-family: 'Proxima Nova Lt', sans-serif;

}

.headerTextC {

	padding-right: 10px;
	font-weight: bold;
	font-family: 'Proxima Nova Lt', sans-serif;

}

.headerTextP {
	text-transform: uppercase;
	padding-left: 5px;
	font-family: 'Original Garamond', serif;
}

.headerTexB {
	text-transform: uppercase;
	color: #2e5775;
	font-family: 'Original Garamond', serif;
}

.subtitle {
	font-style: italic;
	font-size: 24px;
	font-family: 'Proxima Nova Lt', sans-serif;
	padding-top: 30px;
	text-align: right;
}

.promo {

	margin-top: 30px;
	margin-bottom: 150px;
	background-repeat: no-repeat;
}

.promoPic {
	width: 100%;
	transition: all 1s;

}

.headerTextBlock {
	font-size: 40px;
}

.promo {
	margin: 40px 0 30px 0;
}

.headerTop {
	margin-top: 30px;
	row-gap: 20px;
}

.headerTextBlock {
	padding-top: 20px;
}

.subtitle {
	padding-top: 10px;
}


@media (max-width: 1024px) {
	.promo {

		margin-bottom: 100px;
	}

	.wisecityPic {
		width: 130px;
	}
	.headerContent {
		width: 90%;
	}

	.headerTextBlock {
		font-size: 114px;
	}
}

@media (max-width: 850px) {
	.headerTextLeft {
		font-size: 55px;
	}

	.headerTextBlock {
		font-size: 90px;
	}

	.promo {
		margin: 20px 0;
	}
}

@media (max-width: 800px) {
	.headerTextBlock {
		font-size: 80px;
	}

	.wisecityPic {
		right: 19px;
		top: 11vh;
		width: 100px;
	}

}

@media (max-width: 700px) {
	.headerTextBlock {
		font-size: 70px;
	}

	.headerTextLeft {
		font-size: 45px;
	}
}

@media (max-width: 600px) {
	.headerTextBlock {
		font-size: 60px;
	}

	.headerTextLeft {
		font-size: 40px;
	}

	.subtitle {
		font-size: 18px;
	}

}

@media (max-width: 525px) {
	.headerTextBlock {
		font-size: 45px;
	}

	.headerTextLeft {
		font-size: 26px;
	}

	.subtitle {
		font-size: 14px;
	}
}

@media (max-width: 425px) {
	.wisecityPic {
		right: 13px;
		top: 9vh;
		width: 70px;
	}

}

@media (max-width: 375px) {
	.headerTextBlock {
		font-size: 36px;
	}

	.wisecityPic {
		right: 195px;
		top: 1vh;
		width: 70px;
	}
	.promo {
		margin: 10px 0;
	}

}

@media (max-width: 320px) {
	.headerTextLeft {
		font-size: 22px;
	}

	.headerTextBlock {
		font-size: 34px;
	}
}