.container {
    width: 90%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
}

.product {
    margin-bottom: 150px;
}
 
.block {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
}

.blockTop {
    display: flex;
    column-gap: 50px;
    justify-content: flex-start;
    height: 100%;


}

.pic {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.blockTree {
    display: flex;
    align-items: center;
}

.picBlock {
    display: flex;
    justify-content: flex-start;
    margin: 30px 0;
    align-items: flex-start;
}

.picItems {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 30px;
}


.picItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border: 1px solid #e7eaef;
    cursor: pointer;
    
}

.picMini {
    max-width: 90%;
    max-height: 90%;
}

.picTop {
    width: 450px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10%;
    border: 1px solid #e7eaef;

}


.picBig {

    max-width: 90%;
}

.celect {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 20px;

}

.title {
    width: 53%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 0 40px;

}

.blockTitle {
    display: flex;
    flex-direction: column;

}

.textLogo {
    text-align: start;
    font-style: italic;
    font-weight: bold;
    font-family: 'Original Garamond', serif;
    font-size: 18px;
}


.blockCelect {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.title h2 {
    font-size: 36px;
    height: 100px;   
    text-align: left;
    font-family: 'Proxima Nova Lt', sans-serif;

}

.productCelect {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 30px;
    align-items: flex-start;
    font-family: 'Proxima Nova Lt', sans-serif;
    height: 100%;
}

.productListBlock {
    display: flex;
    justify-content: space-between;
}

.productList {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    padding: 15px 0;
    border-bottom: 1px solid #2c2c2c;
    color: #2c2c2c;
    cursor: pointer;
}


.order {
    position: absolute;
    right: -95px;
    color: #fff;
    font-weight: bold;
	text-align: center;
    font-size: 20px;
    width: 160px;
    height: 53px;
    background-color: #FD3737;
    border-radius: 50px 0 0 50px;
    border: none;
    font-family: 'Proxima Nova Lt', sans-serif;
    cursor: pointer;
}

.orderDis {
    position: absolute;
    right: -95px;
    color: #fff;
    font-weight: bold;
	text-align: center;
    font-size: 20px;
    width: 160px;
    height: 53px;
    background-color: #2e5775;
    border-radius: 50px 0 0 50px;
  /*border: 1px solid #2c2c2c;*/
  border-right: none;
    font-family: 'Proxima Nova Lt', sans-serif;
    cursor: pointer;
}


.blockBottom {
    display: flex;
    margin-top: 80px;

}

.blockBottomLeft {
    display: flex;
    flex-direction: column;
    width: 50%;

}

.list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Proxima Nova Lt', sans-serif;


}

.arrow {
    width: 10px;
    height: 10px;
    margin: 0 10px 0 10px;
}

.item {
    cursor: pointer;
    font-size: 16px;
}

.item:last-child {
    cursor: none;
color: #2e5775;

}
.picItem img {
    width: 100%;
}

.item:last-child {
    font-style: italic;

}

.text {

    font-size: 22px;
    width: 80%;
    text-align: left;

}

.picLine {
    margin-top: 100px;
    width: 280px;
    height: 125px;
}

.blockBottomRight {
    display: flex;
    flex-direction: column;
    width: 50%;
    border-left: 1px solid #2c2c2c;

}

.description h3 {
    font-size: 30px;
    text-align: left;
    padding-bottom: 30px;
    font-family: 'Proxima Nova Lt', sans-serif;
}

.garant h3,
.document h3 {
    font-size: 30px;
    text-align: left;
    padding: 30px 0;
    font-family: 'Proxima Nova Lt', sans-serif;
}

.descrTech {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    

}

.description, .garant, .document {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 40px;
    font-family: 'Proxima Nova Lt', sans-serif;

}

.tech {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 30px;
    align-items: flex-end;
    padding: 10px 0;
}

.tech:hover {
    background-color: #e7eaef;
}

.tech p {
    font-size: 16px;
}
.tech p:last-child {
     text-align: right;
}

.techPdf {
    cursor: pointer;
    font-weight: bold;
}
.techPdf:hover {
    color: #2e5775;
}
.line {
    width: 100%;
    border-bottom: 1px solid #0c0c0c;
}

@media (max-width: 1370px) {

	.title h2 {
        font-size: 32px;
   
    }
    
}

@media (max-width: 1024px) {

    .product {
        margin-bottom: 100px;
    }
     

    .block {
        margin-top: 50px;
    }

    .blockTop {
       column-gap: 0;
    }
    
    .picTop {
        width: 250px;
        height: 250px;
         
    }
    .picBig{
        max-width: 100%;
        height: auto;
    
    }
    .picItems{
        margin-top: 0;
    }

    .title h2{
        font-size: 30px;
    }

    .productList {
        padding: 10px 0;
    }
  
    .productCelect {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 30px;
        align-items: flex-end;
    }
    .blockBottom {
        margin-top: 50px;
    }
    .order, .orderDis {
        font-size: 18px;
        right: -50px;
        width: 135px;
        height: 41px;
    }

    .celect {
        font-size: 18px;
        width: 100%;
    }
    .text {
        font-size: 20px;
    }

}

@media (max-width: 768px) {

    .text {
        font-size: 18px;
    }

  .textLogo {
    font-size: 14px;
    margin-top: 30px;
  }
  .blockTop {
    flex-direction: column;
  }
 .picBlock {

    flex-direction: row-reverse;
    justify-content: space-between;
 }

    .picTop {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0;
    }
    .pic {
        width: 100%;
    }
    .picItems {
        flex-direction: row;
        column-gap: 30px;
    }
.title {
    width: 100%;
    margin: 50px 0 0 0;
}
      .productCelect {
        margin-top: 0;
      }
.celect {
font-size: 16px;
}
.order {
    height: 38px;
}
    .description h3 {
        font-size: 26px;
    }
    .title h2 {
        font-size: 28px;
    }
    .blockBottomLeft {
        width: 40%;
    }
    .blockBottomRight {
        width: 60%;
    }


}

@media (max-width: 600px) {
    .title h2 {
        font-size: 20px;
    }
    .picItems {
        flex-direction: column;
        row-gap: 15px;
    }
    .picTop {
        width: 350px;
        height: 350px;
    }
}

@media (max-width: 425px) {
    .productCelect {
        margin-top: 0;
    }
  
    
    .pic {
    width: 100%;
   
}

.picBlock {
    margin: 50px 0;
    flex-direction: column-reverse;
    align-items: center;
  
}
    
.picItems {
    flex-direction: row;
    margin-top: 30px;
}
    .blockTop {
      
        flex-direction: column-reverse;
    }

    .blockBottom {
        margin-top: 50px;
    }
    .title h2 {
        font-size: 22px;
    }
    .title {
        width: 100%;
        margin-top: 30px;
    }
    .list {
    position: absolute;
    top: 13%;
}

    .blockBottomLeft {
        display: none;
    }

    .blockBottomRight {
        width: 100%;
        border-left: none;
    }
    
.description, .garant, .document {
     margin: 0 0 0 0;
  

}
}

