.container {
    width: 90%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid #2C2C2C;
}

.headerTop {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 50px 0 20px 0;
}

.logoBlock {
    display: flex;
}

.menuTop {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
}

.logo {
    width: 260px;
    height: 55px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    background-image: url("../../image/logo.png");
    background-size: cover;
}


.menuBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 50px;
    margin-top: 26px;
    align-items: center;
}

.menuBlockItem {
    font-weight: bold;
    font-size: 18px;
    color: #2C2C2C;
    text-decoration: none;
    font-family: 'Proxima Nova Lt', sans-serif;
    cursor: pointer;
}

.menuBlockItem:hover {
    color: #2e5775;
}


.menuEnter {
    display: flex;
    column-gap: 40px;
    align-items: flex-start;
    margin-top: 20px;
   
}
.close {
 
    width: 20px;
    height: 20px;
    cursor: pointer;
 
}


.blockCard {
    display: flex;
    align-items: flex-start;
}

.profile {
    width: 20px;
    height: 22px;
    margin: 0;
}

.card {
    width: 24px;
    height: 20px;
    margin: 0;
    cursor: pointer;
}

.cardCircle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #FD3737;
    margin-left: -2px;
    opacity: 0;
}

.enterenter {
    color: #2C2C2C;
}

.regLog {
    display: flex;
}

.enter {
    display: flex;
    cursor: pointer;
    font-size: 14px;
}

.cabinet {
    display: flex;
    color: #2e5775;
    font-size: 20px;
    width: 100px;
    height: 24px;
    align-items: center;
    justify-content: space-around;
}

@media (max-width: 1220px) {
    

    .menuBlockItem {
        font-size: 16px;
    }

    .menuBlock {
        column-gap: 20px;
    }
}


