* {
	margin: 0;
	padding: 0;
}

body {
	color: #2C2C2C;
}


.container {
	width: 90%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
}

.headerTop {
	display: flex;
	flex-direction: column;
	margin-top: 100px;
	row-gap: 30px;
	overflow: hidden;

}
.wisecityPic {
	position: absolute;
    right: 75px;
    top: 13vh;
    width: 200px;
	/*left: 63px;*/
}

.headerContent {
	display: flex;
	flex-direction: column;
	line-height: 1;
	font-size: 120px;
	width: 100%;
}

.headerTextLeft {
	text-align: left;
	font-family: 'Proxima Nova Lt', sans-serif;
}


.headerText {
	line-height: 1;
}

.headerTextBlock {
	display: flex;
	line-height: 0.8;
	font-size: 120px;
	font-weight: bold;
	padding-top: 30px;
	font-family: 'Proxima Nova Lt', sans-serif;

}

.headerTextC {

	padding-right: 10px;
	font-weight: bold;
	font-family: 'Proxima Nova Lt', sans-serif;

}

.headerTextP {
	text-transform: uppercase;
	padding-left: 5px;
	font-family: 'Original Garamond', serif;
	font-weight: bold;
}

.headerTexB {
	text-transform: uppercase;
	color:#2e5775;
	font-family: 'Original Garamond', serif;
	font-weight: bold;
}

.subtitle {
	font-style: italic;
	font-size: 30px;
	font-family: 'Original Garamond', serif;
    font-weight: bold;
	padding: 30px 0;
	text-align: right;

}

.order {
	position: fixed;
    right: 0;
    color: #fff;
    font-weight: bold;
	text-align: center;
    font-size: 22px;
    width: 190px;
    height: 50px;
    background-color: #FD3737;
    border-radius: 50px 0 0 50px;
    border: none;
	font-family: 'Proxima Nova Lt', sans-serif;
    top: 530px;
    padding-top: 11px;
	z-index: 100;
	cursor: pointer;
	
}

.promo {

	margin-top: 30px;
	margin-bottom: 100px;
	background-repeat: no-repeat;
}

.promoPic  {
	width: 100%;
	transition: all 1s;
	margin: 0;
}

@media (max-width: 1440px) {
	.headerContent {
		font-size: 100px;	
	 }
	 .wisecityPic {
		right: 20px;
		top: 13vh;
		width: 200px;
	}
	
}

@media (max-width: 1450px) {
	.headerContent {
		font-size: 100px;	
	 }
	

}
	@media (max-width: 1366px) {
		.order {
			top: 493px;	
		 }
		
		
}


@media (max-width: 1232px) {
	.headerContent {
		font-size: 96px;	
	 }
}
@media (max-width: 1200px) {
	
}

@media (max-width: 1120px) {
	
.headerContent {
	width: 90%;
}
}
