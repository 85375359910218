.container {
	width: 90%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
}

.logo {
	width: 150px;
	height: 30px;

}

.burger {
	width: 50px;
	height: 50px;
}

.blockContacts {
	display: flex;
	margin-bottom: 110px;
	overflow: hidden;
	width: 100%;
}

.menuContacts {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.usContacts {
	margin-top: 50px;
	display: flex;
	width: 60%;
}

.usContacts h1 {
	text-transform: uppercase;
	margin-bottom: 50px;
}

.title h3 {
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
}

.usContacts h3 {
	font-size: 40px;
	width: 730px;
	margin: 0;
	font-weight: normal;
}

.usContacts h3:first-child {
	color: #2e5775;
	font-style: italic;
	font-weight: bold;
}


.contacts {
	overflow: hidden;
}

.btnContact {

	color: #fff;
	font-weight: bold;
	font-size: 20px;
	width: 190px;
	height: 56px;
	background-color: #FD3737;
	border-radius: 50px;
	border: none;
	font-family: 'Proxima Nova Lt', sans-serif;
	margin-top: 30px;
}

.connect {

	width: 360px;
	height: 330px;
	background: #2c2c2c;
	display: flex;
	justify-content: center;
	margin-top: 5%;
}

.connectQuestion {
	width: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.lineContacts {
	margin-top: 70px;
	border-bottom: 1px solid #2c2c2c;
}

.futerContacts {
	background-color: #2c2c2c;
	color: #fff;
}

.itemsContacts {
	padding: 50px 0 50px 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	row-gap: 50px;
	column-gap: 30px;
}

.item {
	display: flex;
	flex-direction: column;
}

.textBux {
	padding-top: 23px;
	text-decoration: underline;
}

.text {
	text-decoration: underline;
}

.mail,
.phone,
.textAdress {
	font-size: 20px;
	margin: 0;
	padding-top: 20px;
	font-family: 'Proxima Nova Lt', sans-serif;
}

.textAdress {
	padding-top: 10px;
}

.store {
	margin-top: 30px;
}

iframe {
	width: 100%;
	height: 357px;
	cursor: pointer;
	margin-bottom: 50px;
}

.futterBottom {
	display: flex;
	justify-content: space-between;
	border-top: 1px solid #fff;
	padding: 30px 0 30px 0;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
}

.futterCopi {
	font-size: 12px;
}

@media (max-width: 1440px) {
	.connect {
		margin-left: 65%;
	}

}

@media (max-width: 1220px) {
	.connect {
		margin-left: 60%;
	}

	.usContacts h3 {
		font-size: 36px;
		width: 640px;
	}
}

@media (max-width: 1024px) {

	.usContacts h1 {
		font-size: 44px;
	}

	.connect {
		margin-left: 55%;
	}

	.usContacts h3 {
		font-size: 32px;
		width: 500px;
	}
}

@media (max-width: 900px) {
	.usContacts h3 {
		font-size: 26px;
	}

	.futterWrapper {
		width: 250px;
	}
}

@media (max-width: 768px) {
	.usContacts h1 {
		font-size: 40px;
	}

	.usContacts {
		margin-top: 30px;
		width: 100%;
	}

	.usContacts h3 {

		font-size: 28px;
		width: 100%;
	}

	.item:last-child {

		display: flex;
		flex-direction: row;
	}

	.blockContacts {
		flex-direction: column;
	}

	.store {
		margin-top: 0;
		margin-left: 50px;
	}


	.blockContacts {
		margin-bottom: 50px;
	}

	.connect {
		width: 100%;
		background-color: #fff;
		margin: 0;
	}

	.futterWrapper {
		width: 100%;
	}

	.futterWrapper .futterRight {
		background-color: #fff;
	}

	.futterQvest {
		display: none;
	}

	input {
		height: 50px;
	}

	input, textarea {
		border-bottom: 1px solid #2c2c2c;
		font-size: 20px;
		margin-top: 0;
	}

	.futterBtn {
		margin: 20px 0 0 70%;
	}

}

@media (max-width: 500px) {
	.futterBtn {
		margin: 20px 0 0 60%;
	}
}

@media (max-width: 425px) {
	.usContacts h1 {
		font-size: 32px;
	}

	.usContacts h3 {
		font-size: 28px;
	}

}

@media (max-width: 375px) {

	.usContacts h3 {
		font-size: 22px;
	}
}