.container {
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	color:#fff;
}


.subcatalog {
	display: flex;
    flex-direction: column;
	align-items: center;
	background-color: #2C2C2C;
	color:#fff;
	z-index: 100;
	width: 25%;
	margin-left: 0px;
	transition: all, 1s;
	

}
.subcatalog h3 {
	color:#fff;
}
.subcatalogТор{
	width: 90%;
	display: flex;
	justify-content: space-between;
	margin-top: 50px;
    align-items: center;
	margin-left: auto;
	margin-right: auto;
}

.subcatalogТор h3 {
	font-size: 30px;
	font-family: 'Proxima Nova Lt', sans-serif;
	font-weight: normal;
	
}

.catalogWater {
	display: flex;
	flex-direction: column;
	row-gap: 50px;
	color: #fff;
	padding: 50px 0;
}


@keyframes sub {  
	from {
		background-color: #2c2c2c;
		color: white;
	  } 
	to {
		background-color: white;
		color: #2c2c2c;
		
	  }	
}
@keyframes sub2 {  
from {
		background-color: white;
		color: #2c2c2c;
		
	  }	

	to {
		background-color: #2c2c2c;
		color: white;
	  } 
	
}


.catalogMachWater {
	background-color: #2C2C2C;
	text-decoration: none;
	color: #fff;
	font-family: 'Proxima Nova Lt', sans-serif;
	cursor: pointer;
	border-bottom: 1px solid;
    padding: 20px 0;
	font-size: 20px;
	animation: sub2 0.5s linear   1 backwards;
	
}
.catalogMachWater:hover {
	background-color: #fff;
	text-decoration: none;
	color: black;
	animation: sub 1s linear   1 backwards;
}

.subcatalogItems {
	display: flex;
	flex-direction: column;
	column-gap: 50px;
	
}

.subcatalogItem {
	display: flex;
}
.catalogWater h3 {
	font-size: 26px;
	width: 30%;
	padding-bottom: 30px;
	font-family: 'Proxima Nova Lt', sans-serif;
}

h3:last-child {
	margin-left: 20px;
}

.machwater, .ultrawater {
	/*width: 80%;*/
	display: flex;
	flex-direction: column;
}

.machwaterblock, .ultrawaterblock {

	display: flex;
	justify-content: space-between;
}

.ultrawaterblock {
	margin: 50px 0 100px 0;
}

.machwater .line,
.ultrawater .line {
	width: 100%;
	border-bottom: 1px solid #fff;
	margin: 15px 0;
}


@media (max-width: 1220px) {

	.machwater, .ultrawater {
		width: 90%;
	}
}
	
@media (max-width: 1024px) {


	.subcatalogТор{
		align-items: center;
	}
	.subcatalogТор h3 {
		font-size: 28px;
	}

	.subcatalogItems h3:last-child {
		margin-left: 160px;
	}

	.close {
		width: 20px;
		height: 20px;
	
	}
	.catalogMachWater {
		font-size: 18px;

	}

	.picItem {
		margin-top: 0;
	}


}
@media (max-width: 800px) {
	.subcatalog {
		width: 50%;
	}
	


}	
@media (max-width: 425px) {
		
	.subcatalog {
		width: 50%;
	
	}

	.catalogMachWater {
		font-size: 16px;
	}
	.machwater {
		width: 100%;
	}	
	
	.close {
		width: 15px;
		height: 15px;
	
	}
}
