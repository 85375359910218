

.container {
	width: 90%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
}

h1 {
	margin: 30px 0;
	font-size: 56px;
	font-family: 'Proxima Nova Lt', sans-serif;
}

.list {

	display: flex;
	justify-content:space-between;
	align-items: center;
	width: 100%;
    animation: doc2 0.5s linear   1 backwards;
	font-size: 24px;
	font-family: 'Proxima Nova Lt', sans-serif;
	font-weight: normal;
	
}

.list:hover {
    background-color: #2c2c2c;
	color: white;
	animation: doc 1s linear   1 backwards;
}

.list:hover .col_1 {
	animation: doc 1s linear   1 backwards;
	color: white;
}

.listBlock {
	margin-bottom: 30px;
}

.col_1, .col_2,
.col_3, .col_4 {
	display: flex;
	align-items: center;
	font-size: 18px;
	text-transform: uppercase;
	font-family: 'Proxima Nova Lt', sans-serif;
	font-weight: normal;
	height: 70px;
	border-bottom: 1px solid #2c2c2c;
	padding: 10px;
}

.col_1 {
	width: 25%;
	border: none;
	font-size: 24px;
}

.col_2 {
width: 40%;
}
.col_3 {
	width: 15%;
	display: flex;
	justify-content: flex-start;
	cursor: pointer;
}
.col_3:hover{
color: #2e5775;

}
.col_4{
	width: 20%;
display: flex;
justify-content:flex-end;
}

@keyframes doc {
	from {
		background-color: white;
		color: #2c2c2c;
		
	  }
	  to {
		background-color: #2c2c2c;
		color: white;
	  } 
	
}
@keyframes doc2 {
	  from {
		background-color: #2c2c2c;
		color: white;
	  } 
	
	to {
		background-color: white;
		color: #2c2c2c;
		
	  }
	
}

@media (max-width: 1024px) {

    h1 {
		font-size: 44px;
    }

	.col_1, .col_2,
.col_3, .col_4 {
	
	font-size: 16px;
	
	height: 70px;
	
}
.col_2 {
	width: 100%;
	border-bottom: none;
}
.col_3 {
	width: 10%;
	border-bottom: none;
}
.list {
	border-bottom: 1px solid #2c2c2c;
}

}

@media (max-width: 768px) {
	h1 {
		font-size: 40px;
    }
  
.col_3 {
	width: 20%;
	justify-content: flex-end;
}
.col_4 {
display: none;
}

}

@media (max-width: 425px) {
	h1 {
		font-size: 32px;
  }
  .col_1, .col_2,
.col_3, .col_4 {
	
	text-transform: none;
	padding: 0;
	
}

}

@media (max-width: 425px) {
	 .col_1, .col_2,
.col_3, .col_4 {
	text-transform: none;
	padding: 0;
	
}

}