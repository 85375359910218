.container {
	width: 90%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
}
.generalCatalog {
	width: 200%;
	display: flex;
}
.catalogblock {
	display: flex;
	flex-direction: column;
}

.line {
	width: 100%;
	border-bottom: 0.5px solid #ffffff;
	padding-bottom: 50px;
}

.pageCatalog {
	width: 50%;
	background-color: #2C2C2C;
}

.catalog {
	padding: 100px 0;
	color: #ffffff;
	font-family: 'Proxima Nova Lt', sans-serif;
}

.catalogList {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: 30px;
}

.catalogBox {
	display: flex;
	column-gap: 50px;
}

.catalogTitle {
	font-size: 50px;
	text-transform: uppercase;
	padding-bottom: 50px;
}

.catalogblock {
	
	display: flex;
	row-gap: 30px;
}

.undercatalog {
	width: 40%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	row-gap: 30px;
}

.catalogBlockMain {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.catalogBlockMedium {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	column-gap: 50px;
	margin-top: 30px;
}

.catalogBlockBigText {
	position: relative;
	cursor: pointer;
}

.catalogBlockBigTextDisact {
	position: relative;
	opacity: 0.3;

}
.catalogBlockBigText:hover .catalogPic {
	opacity: 1;
}
.catalogBlockBigTextDisact:hover .catalogPic {
	opacity: 1;
}

.catalogPic {
	width: 200px;
	
	position: absolute;
	margin-top: -50px;
	margin-left: 300px;
	opacity: 0;
	transition: all, 1s;
}

.catalogBlockMediumText {
	font-size: 26px;
	cursor: pointer;
	color: #fff;
}

.catalogBlockMiniText {
	font-size: 22px;
	font-style: italic;
}
@media (max-width: 1440px) {

	.catalogPic {
		margin-left: 200px;
	}
	
}



@media (max-width: 1366px) {
	.catalog {
	padding: 50px 0;
}
.catalogPic {
	margin-left: 100px;
}

}


@media (max-width: 1220px) {
	.catalogPic {
		margin-left: 100px;
	}

}

@media (max-width: 1100px) {
	.catalogPic {
		margin-left: 50px;
	}

}
@media (max-width: 1024px) {
	
.catalog {
	padding: 40px 0;	
	}
.catalogTitle {
	font-size: 44px;
	}
    .catalogPic {
		display: none;
	}

	.undercatalog {
		width: 50%;
	}
	
	.catalogList {
		font-size: 30px;
	}

	.line {
		padding-bottom: 40px;
	}
.catalogBlockMediumText {
	font-size: 26px;
	}
.generalCatalogM {
	width: 100%;
	}
}

@media (max-width: 768px) {
	
.catalogTitle {
	font-size: 40px;
	padding: 20px 0;
	}
	
	
	.catalogList {
		font-size: 28px;
	}

	.line {
		padding-bottom: 34px;
	}
.catalogBlockMediumText {
	font-size: 24px;
	}
}

@media (max-width: 425px) {
	.pageCatalog {
		width: 50%;
	}
	.catalogTitle {
		font-size: 32px;
	}

	.catalogList {
			font-size: 24px;
			flex-direction: column;
		}

		.line {
			padding-bottom: 30px;
		}

	.catalogBlockMediumText {
		font-size: 20px;
	}

	.undercatalog {
		width: 100%;
    row-gap: 0;
    margin-top: 20px;
	margin-left: 80px;
	}

	
}
@media (max-width: 375px) {
	.pageCatalog {
		width: 50%;
	}
	.catalogTitle {
		font-size: 30px;
	}

	.catalogList {
			font-size: 20px;
			flex-direction: column;
		}

		
		.line {
			padding-bottom: 26px;
		}

	.catalogBlockMediumText {
		font-size: 20px;
	}

	.undercatalog {
		width: 100%;
    row-gap: 0;
    margin-top: 20px;
	margin-left: 80px;
	}

	
}
@media (max-width: 320px) {
	.catalogBox {
		column-gap: 20px;
	}

	.undercatalog {
		margin-left: 50px;
	}

	
}