.page2 {
	padding-bottom: 150px;
	position: relative;
	z-index: 7;
 }
 .container {
	width: 90%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
}
.about {
    display: flex;
    flex-direction: column;
    row-gap: 80px;
    
 }
 
 .pageAbout {
	padding-top: 20px;
	width: 70%;
	display: flex;
	flex-direction: column;
align-items: flex-start;
 }


 .text1, .text2, .text3, .text4 {
	font-size: 50px;
	font-weight: 100;
 }

.text1 span, .text4 {
font-style: italic;
color: #2e5775;
font-weight: bold;

}

.pageAboutNumbers {
	
	display: flex;
	flex-direction: row;
	justify-content: space-between;
    column-gap: 30px;
 }

 .number {
	font-size: 140px;
	font-weight: bold;
	font-family: 'Proxima Nova Lt', sans-serif;
	text-align: center;
 }
 .numberBlock {
	display: flex;
	column-gap: 30px;
 }

 .textOne, .textTwo {

	font-size: 24px;
	font-family: 'Proxima Nova Lt', sans-serif;

 }

 .command {
	display: flex;
	justify-content: flex-end;
	font-size: 26px;
	color: #2e5775;
	text-decoration: underline;
	font-family: 'Proxima Nova Lt', sans-serif;

 }

 @media (max-width: 1440px) {

	.text1, .text2, .text3, .text4 {
		font-size: 42px;
	}
	.number {
		font-size: 120px;
	 }

 }

 @media (max-width: 1220px) {
	
	.pageAbout {
	width: 90%;
			
	}

	.text1, .text2, .text3, .text4 {
		font-size: 34px;
	}

	.number {
		font-size: 90px;
	 }

	 .textOne, .textTwo {
		font-size: 20px;
	}

 }
 
 @media (max-width: 1024px) {

 .container {
	width: 100%;
}
 }
 @media (max-width: 800px) {
	
	.pageAbout {
	width: 100%;
	padding: 0;	
			
	}
	.numberBlock {
	flex-direction: column;
	 }
	
	.number {
		font-size: 70px;
	
	 }
	

	 .textOne, .textTwo {
		font-size: 16px;
font-style: italic;
	}
	.text1, .text2, .text3, .text4 {
		font-size: 30px;
		
	}
	.command {
		font-size: 24px;
	}

 }

 @media (max-width: 430px) {
	.page2 {
		padding-bottom: 0;
		
	 }

	.text1, .text2, .text3, .text4 {
		font-size: 20px;
	}

	.number {
		font-size: 30px;
	 }

	 .textOne, .textTwo {
		font-size: 14px;
	}

	.pageAboutNumbers {
	
	display: none;
	 }
	 .command {
		font-size: 20px;
	 }
	 .about {
			row-gap: 50px;
	
	 }

 }

 @media (max-width: 375px) {

	.text1, .text2, .text3, .text4 {
		font-size: 17px;
	}
 }



