.technology {
	height: 100%;
	margin-top: 150px;
}

.container {

	width: 90%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
}

.technologyBlock {
	display: flex;
	justify-content: space-between;
}

.technologyTitle {
	
	transform: translate(0px, 0px);
	font-size: 50px;
	text-transform: uppercase;
	font-family: 'Proxima Nova Lt', sans-serif;
}

.technologyCards {
	width: 70%;
	display: flex;
	flex-direction: column;
	cursor: pointer;

}


.technologyCard {
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid #2c2c2c;
	padding: 100px 0;

}

.cardLora {
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid #2c2c2c;
	padding: 100px 0;

}
.technologyBlockCenter {
	display: flex;

}

.blockNum {
	display: flex;
	width: 20%;

}

.technologyNumber {
	font-size: 30px;
	font-family: 'Proxima Nova Lt', sans-serif;

}

.technology h3 {
	font-size: 30px;
	font-family: 'Proxima Nova Lt', sans-serif;
	font-weight: normal;

}

.technologyText {
	font-size: 20px;
	font-family: 'Proxima Nova Lt', sans-serif;
	width: 60%;
	margin-left: 10%;
}

@media (max-width: 1440px) {

	.technologyCards {
		width: 65%;
	}
	
	.blockNum {
		width: 25%;

	}
	.technologyText {
		width: 100%;
	}
}
	 

@media (max-width: 1280px) {
	.technologyText {
		font-size: 18px;
	}
	.blockNum {
		width: 30%;

	}

	}
@media (max-width: 1220px) {
	.technologyNumber, .technology h3 {
		font-size: 28px;
	}
	
}

@media (max-width: 1024px) {
	.technologyTitle {
		font-size: 44px;
	}
	.technology {
		margin-top: 50px;
	}
	.technologyBlock {
flex-direction: column;
	}
	
	.technologyCards {
		width: 100%;
	}
	.blockNum {
		width: 25%;

	}
}
@media (max-width: 880px) {
	
	.blockNum {
		width: 30%;

	}
	.technology h3, 
	.technologyNumber {
		font-size: 26px;
	}
}

@media (max-width: 768px) {

	.technologyTitle {
		font-size: 40px;
	}
.technologyText {
		font-size: 18px;
	}

	.cardLora {
		padding: 60px 0;
	
	}
}

@media (max-width: 425px) {

	
	.technologyTitle {
		font-size: 32px;
	}
	.technologyText {
			font-size: 16px;
			padding-top: 20px;
			width: 80%;
		}
	.technologyBlockCenter {
		flex-direction: column;
	}
		.cardLora {
			padding: 40px 0;
		
		}
		.technologyCard {
			padding: 40px 0;
		}
		.blockNum {
			width: 40%;
		}
	}

	@media (max-width: 372px) {

	
		.technologyTitle {
			font-size: 32px;
		}
		.technologyText {
				font-size: 16px;
				padding-top: 20px;
				width: 80%;
			}
		.technologyBlockCenter {
			flex-direction: column;
		}
			.cardLora {
				padding: 40px 0;
			
			}
			.technologyCard {
				padding: 40px 0;
			}
			.blockNum {
				width: 35%;
			}
		}