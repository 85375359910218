

.logForm {
    position: absolute;
    width: 450px;
    max-width: 100%;
    margin: -220px 37% 0;
    top: 45%;
    transition: right 1s;
    font-family: 'Original Garamond';
    font-style: normal;
    z-index: 130;
    border: 1px #2c2c2c solid;
}

.blockTop {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    height: 60px;
}

.logForm span {
    cursor: pointer;
}

.formLog {
    height: 100%;
}

.form {
    background-color: #2c2c2c;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0 0 0;

}

.hello {
    font-size: 20px;
    color: #2c2c2c;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Proxima Nova Lt', sans-serif;

}

.helloRegisrt {
    font-size: 20px;
    color: #2c2c2c;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Proxima Nova Lt', sans-serif;
}

.close, .closeReg {
    cursor: pointer;
    font-size: 32px;
    position: absolute;
    right: -35px;
    color: #000;
}

.blockReg {
    display: flex;
    margin-bottom: 30px;

}

.helloReg {
    font-size: 16px;
    padding-right: 5px;
    color: #fff;
}

.helloReg:last-child {
    text-decoration: underline;
    cursor: pointer;
}

.errorLogin {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    background-color: #2c2c2c;
}

.errorClose {
    margin: 17px 0 0 10px;
}

.errorText {
    font-size: 16px;
    color: #FD3737;
    font-family: 'Proxima Nova Lt', sans-serif;
    margin-top: 16px;
}

.mailForm {
    width: 70%;
     height: 80px;
}

.passvordForm, .passvordBox,
.phoneBox, .companyBox, .unpBox {
    width: 70%;
    height: 80px;
    margin-top: 20px;
    font-style: normal;

}

.email,
.passvord {
    font-size: 14px;
    padding-bottom: 10px;

}

.mail,
.formPassvord {
    font-family: 'Proxima Nova Lt', sans-serif;
    height: 40px;
    font-size: 16px;
    margin-top: 5px;
    padding-left: 10px;
    color: #2c2c2c;

}

.mail .message,
.formPassvord .message {
    font-style: normal;
}

.formLogBtn {
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    width: 70%;
    height: 40px;
    background-color: #FD3737;

    border-radius: 50px;
    border: none;
    margin: 30px 0 50px;
    font-family: 'Proxima Nova Lt', sans-serif;
}

@media (max-width: 1220px) {
 
}


@media (max-width: 1030px) {
    .logForm {
        margin: -220px 27% 0;
    }
}
@media (max-width: 800px) {
    .logForm {
        margin: -220px 22% 0;
    }
}

@media (max-width: 660px) {
    .logForm {
        margin: -220px 15% 0;
       
    }
}
@media (max-width: 430px) {
    .logForm {
         width: 80%;
         margin: -300px 9% 0;
    }
.hello {
    font-size: 18px;
}
    .blockReg {
        
        justify-content: center;
        flex-direction: row;
    }

    .mailForm, .passvordForm {
        width: 80%;
    }
}

@media (max-width: 375px) {
    .form {
        padding:  20px 0 20px;

    }

}